import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../axios";

const initialState = {
  validators: { loading: false, data: [] },
  selectedValidator: { loading: false, data: null },
};


export const createTempQrCode = createAsyncThunk(
  "temporaryQrCode/createQrCode",
  async (data) => {
    try {
      const response = await axiosInstance.post("/temporaryQrCode", data);
      return response.data;
    } catch (error) {
      const customError = {
        name: "Temp QR Code Creation error",
        message: error.response.data.message,
      };
      throw customError;
    }
  }
);

export const getTempQrCodeFromValidator = createAsyncThunk(
  "temporaryQrCode/getQrCodeFromValidator",
  async (id) => {
    try {
      const response = await axiosInstance.get(`/temporaryQrCode`);
      return response.data;
    } catch (error) {
      const customError = {
        name: "Temp QR Code get error",
        message: error.response.data.message,
      };
      throw customError;
    }
  }
);


const tempQrCodeSlice = createSlice({
  name: "temporaryQrCode",
  initialState,
  reducers: {},
  extraReducers: {
    [createTempQrCode.pending]: (state) => {
      state.validators.loading = true;
    },
    [createTempQrCode.fulfilled]: (state, action) => {
      state.validators.loading = false;
      state.validators.data = action.payload;
    },
    [createTempQrCode.rejected]: (state) => {
      state.validators.loading = false;
    },

    [getTempQrCodeFromValidator.pending]: (state) => {
      state.validators.loading = true;
    },
    [getTempQrCodeFromValidator.fulfilled]: (state, action) => {
      state.validators.loading = false;
      state.validators.data.push(action.payload);
    },
    [getTempQrCodeFromValidator.rejected]: (state) => {
      state.validators.loading = false;
    },
  },
});

export default tempQrCodeSlice.reducer;
