import {
  Breadcrumb,
  Button,
  Card,
  Input,
  Typography,
  DatePicker,
  Tooltip,
} from "antd";
import React from "react";
import {
  SearchOutlined,
  PlusOutlined,
  ReloadOutlined,
  FilterOutlined,
  FileExcelOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { style } from "../../Styles";
import { t } from "i18next";
import { CSVLink } from "react-csv";
const { Text } = Typography;
function Header({
  addButtonText,
  onAddButtonClick,
  onSearch,
  searchPlaceholder,
  addButtonBackgroundColor,
  addButtonBorderColor,
  addButtonTextColor,
  onReload,
  showAddButton,
  BreadcrumbItems,
  showBreadcrumb,
  showFilterButton,
  onClickFilterButton,
  showExportCSV,
  csvHeaders,
  csvData,
  generateCSVfn,
  csvDataMiddleFileName,
  showFilterByDate = false,
  onFilterByDate,
  dateRange,
  showSummaryButton,
  onClickSummaryButton,
  summaryButtonText,
}) {
  const primaryColor = process.env.REACT_APP_PRIMARY_COLOR;
  const secondaryColor = process.env.REACT_APP_SECONDARY_COLOR;
  const { RangePicker } = DatePicker;
  return (
    <Card style={style?.headerCard}>
      <div style={style.headerContainer}>
        {showBreadcrumb && BreadcrumbItems && BreadcrumbItems.length > 0 && (
          <Breadcrumb style={style.headerBreadcrumb} items={BreadcrumbItems} />
        )}
        <div style={style.leftItems}>
          <Button
            onClick={() => {
              if (onReload) {
                onReload();
              }
            }}
            style={{
              ...style.headerButton,
              //float: "left",
            }}
            size="large"
          >
            <ReloadOutlined />
          </Button>
        </div>

        {showFilterByDate && (
          <div style={{ ...style.centerItem }}>
            <RangePicker
              style={{ ...style.headerButton }}
              value={dateRange}
              onChange={onFilterByDate}
              allowClear={false}
              placeholder={[
                t("drawerFilter.startDate"),
                t("drawerFilter.endDate"),
              ]}
            />
          </div>
        )}
        <div style={style.rightItems}>
          {showAddButton && (
            <Button
              onClick={onAddButtonClick}
              style={{
                ...style.headerButton,
                backgroundColor: addButtonBackgroundColor || primaryColor,
                color: addButtonTextColor || "#ffffff",
                float: "right",
              }}
              size="large"
              icon={<PlusOutlined />}
            >
              <Text
                strong
                style={{ ...style.headerButtonText, color: addButtonTextColor }}
              >
                {addButtonText}
              </Text>
            </Button>
          )}
          {showSummaryButton && (
            <Tooltip title={summaryButtonText}>
              <Button
                onClick={onClickSummaryButton}
                style={{
                  ...style.headerButton,
                  backgroundColor: "#ffffff",
                  color: primaryColor,
                  float: "right",
                }}
                size="large"
                icon={<InfoCircleOutlined />}
              >{summaryButtonText}</Button>
            </Tooltip>
          )}
          {showFilterButton && (
            <Button
              icon={<FilterOutlined />}
              onClick={onClickFilterButton}
              style={{
                ...style.headerButton,
                backgroundColor: addButtonBackgroundColor || primaryColor,
                color: addButtonTextColor || "#ffffff",
                float: "right",
              }}
              size="large"
            >
              {t("drawerButtons.filter")}
            </Button>
          )}
          {showExportCSV && (
            <CSVLink
              data={csvData}
              headers={csvHeaders}
              asyncOnClick={true}
              onClick={generateCSVfn}
              filename={`export-${csvDataMiddleFileName}-${new Date()
                .toISOString()
                .slice(0, 30)}.csv`}
            >
              <Button
                icon={<FileExcelOutlined />}
                size="large"
                style={{
                  ...style.headerButton,
                  color: primaryColor,
                  float: "right",
                }}
              >
                {t("drawerButtons.exportCsv")}
              </Button>
            </CSVLink>
          )}
          <div
            style={
              {
                /*...style.leftItems,  width: "100%" */
              }
            }
          >
            <Input
              onChange={onSearch}
              size="large"
              placeholder={searchPlaceholder}
              style={style.headerInput}
              prefix={<SearchOutlined />}
            />
          </div>
        </div>
      </div>
    </Card>
  );
}

export default Header;
