import React, { useState } from "react";
import { Form, Input, Select, Button, Divider, Spin, Tag, DatePicker } from "antd";
import { useSelector } from "react-redux";
import { useForm } from "antd/es/form/Form";
import { style } from "../../Styles";
import { bundleType } from "../../Utils/Constants/utils";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import localeData from "dayjs/plugin/localeData";

const BundleForm = ({
  onSave,
  onSaveFailed,
  bundle,
  cardTypes,
  onCancel,
  onDirty,
  unsavedChanges,
}) => {
  const { RangePicker } = DatePicker;
  const dateFormat = "YYYY/MM/DD";
  dayjs.extend(localeData);
  dayjs.extend(utc);
  const { t } = useTranslation();
  const [form] = useForm();
  const initialValues = {
    ...bundle,
    cardType: bundle?.cardType?.id,
    dateRange: [dayjs(bundle?.bundleValidityFrom), dayjs(bundle?.bundleValidityTo)],
    kioskType: bundle?.kioskType == null ? "ALL" : bundle?.kioskType 
  };
  const [selectedType, setSelectedType] = useState(initialValues.type);
  const [dateRange, setDateRange] = useState([dayjs(initialValues?.bundleValidityFrom), dayjs(initialValues?.bundleValidityTo)])
  const selectType = (type) =>{
    setSelectedType(type)
  }
  const onDateRangeChange = (value) => {
    setDateRange(value)
  }
  const onSaveValue = (values) => {
    
    let bundleValues = {
      ...values,
      cardType: values.cardType ? {id : values.cardType} : null,
      bundleValidityFrom: selectedType == "SPECIAL_CARD_TYPE" ? dateRange[0].format("YYYY-MM-DD") : null,
      bundleValidityTo: selectedType == "SPECIAL_CARD_TYPE" ? dateRange[1].format("YYYY-MM-DD") : null,
      numberOfTrips: selectedType == "SPECIAL_CARD_TYPE" ? null : values.numberOfTrips,
      kioskType: (values?.kioskType != null && values?.kioskType != "ALL") ? values?.kioskType : null
    }
    onSave(bundleValues)
  }
  return (
    <Form
      form={form}
      name="bundle"
      layout="vertical"
      initialValues={initialValues}
      onFinish={onSaveValue}
      onFinishFailed={onSaveFailed}
      autoComplete="off"
      onValuesChange={() => onDirty()}
    >
      <Form.Item
        label={t("bundleScreen.form.bundleType")}
        name="type"
        rules={[
          { required: true, message: t("bundleScreen.form.pleaseSelectType") },
        ]}
      >
        <Select size="medium" optionLabelProp="label" onChange={selectType} disabled={bundle != null}>
          {bundleType.map((type) => (
            <Select.Option
              key={type.value}
              value={type.value}
              label={type.label}
            >
              {type.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label={t("bundleScreen.form.bundleName")}
        name="name"
        rules={[
          {
            required: true,
            message: t("bundleScreen.form.pleaseEnterBundleName"),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={t("bundleScreen.form.bundlePrice")}
        name="price"
        rules={[
          {
            required: true,
            message: t("bundleScreen.form.pleaseEnterBundlePrice"),
          },
        ]}
      >
        <Input type="number" min={0} step={0.01} placeholder="€0.00" />
      </Form.Item>
      {selectedType !== "SPECIAL_CARD_TYPE" && (
        <Form.Item
        label={t("bundleScreen.form.bundleNumberOfTrips")}
        name="numberOfTrips"
        rules={[
          {
            required: initialValues?.type === "SPECIAL_CARD_TYPE",
            message: t("bundleScreen.form.pleaseEnterNumberOfTrips"),
          },
        ]}
      >
        <Input type="number" min={0} step={1} placeholder="0" />
      </Form.Item>)}
      {selectedType === "SPECIAL_CARD_TYPE" && 
      (<Form.Item
        label={t("bundleScreen.form.cardType")}
        name="cardType"
        rules={[
          { required: selectedType === "SPECIAL_CARD_TYPE", message: t("bundleScreen.form.pleaseSelectCardType") },
        ]}
      >
        <Select size="medium" optionLabelProp="label" >
          {cardTypes.map((cardType) => (
            <Select.Option
              key={cardType.id}
              value={cardType.id}
              label={cardType.name}
            >
              {cardType.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>)}
      {selectedType === "SPECIAL_CARD_TYPE" && (
        <Form.Item
        label={t("bundleScreen.form.bundleValidityDateRange")}
        name="dateRange"
        >
        <RangePicker
          onChange={onDateRangeChange}
          /* initialValues={dateRange} */
          format={dateFormat}
          style={{ width: "100%" }}
          allowClear={false}
          placeholder={[
            t("bundleScreen.form.startDate"),
            t("bundleScreen.form.endDate"),
          ]}
        />
      </Form.Item>)}
      <Form.Item
        label={t("bundleScreen.form.kioskType")}
        name="kioskType"
      >
        <Select size="medium" optionLabelProp="label">
          <Select.Option default key={"ALL"} value={"ALL"} label={t("kioskTypes.all")}>{t("kioskTypes.all")}</Select.Option>
          <Select.Option key="KIOSK_STATION" value="KIOSK_STATION" label={t("kioskTypes.KIOSK_STATION")}>{t("kioskTypes.KIOSK_STATION")}</Select.Option>
          <Select.Option key="PORTABLE_KIOSK" value="PORTABLE_KIOSK" label={t("kioskTypes.PORTABLE_KIOSK")}>{t("kioskTypes.PORTABLE_KIOSK")}</Select.Option>
          <Select.Option key="HANDHELD_KIOSK" value="HANDHELD_KIOSK" label={t("kioskTypes.HANDHELD_KIOSK")} >{t("kioskTypes.HANDHELD_KIOSK")}</Select.Option>
        </Select>
      </Form.Item>
      <Divider />
      <Form.Item style={{ float: "right" }}>
        <Button
          type="default"
          style={{ marginBottom: 32, marginRight: 8 }}
          onClick={() => onCancel(unsavedChanges)}
        >
          {t("drawerButtons.cancel")}
        </Button>
        <Button
          type="primary"
          style={{ marginBottom: 32 }}
          htmlType="submit"
          disabled={!unsavedChanges}
        >
          {bundle ? t("drawerButtons.save") : t("drawerButtons.add")}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default BundleForm;
