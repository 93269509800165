import React, { useEffect, useState, } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import Header from "../../global/Header/Header";
import MapWithMultipleMarkers from "../../global/GoogleMaps/MapWithMultipleMarkers";
import { useTranslation } from "react-i18next";
import { message } from "antd";
import { getAllValidators } from "../../redux/validatorSlice";
import dayjs from "dayjs";
import { getLastLocationValidators } from "../../redux/validatorLogSlice";
import { useLoadScript } from "@react-google-maps/api";

const libraries = ["core", "places", "geocoding"];

const ValidatorTracking = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries,
      });

    const [filter, setFilter] = useState('');

      
    const { validatorLogs } = useSelector((state) => state.validatorLogs);
    const [locations, setLocations] = useState([]);

    useEffect(() => {
        fetchAllValidators();
      }, [dispatch]);

    const fetchAllValidators = () => {
    dispatch(getLastLocationValidators())
        .unwrap()
        .then((data) => { 
            console.log("data", data)
            setLocations(data) })
        .catch(() =>
        message.error(
            t("validatorScreen.messages.anErrorOccurredWhileFetchingValidators")
        )
        );
    };
    return (
        <>
            <Header
            onSearch={(e) => {
            setFilter(e.target.value);
            }}
            searchPlaceholder={t("validatorTracking.header.search")}
            addButtonTextColor={"#ffffff"}
            onReload={fetchAllValidators}
            showAddButton={false}
            BreadcrumbItems={[
            {
                title: t("validatorTracking.header.title"),
            },
            ]}
            showBreadcrumb={true}
            showExportCSV={false}
            />
            <div style={{margin: "2%"}} >
                {isLoaded && <MapWithMultipleMarkers locations={locations} />}
            </div>

        </>
    );
}

export default ValidatorTracking;