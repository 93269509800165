import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../axios";

// Define the async thunk for fetching the data
export const getAllValidatorLogs = createAsyncThunk(
  "validatorLog/getAllLogs",
  async () => {
    try {
      const response = await axios.get("/validators-log");
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getValidatorLogsByDateRange = createAsyncThunk(
  "validator/getValidatorLogsByDateRange",
  async (dateRange) => {
    try {
      const response = await axios.patch(`/validators-log`, dateRange);
      return response.data;
    } catch (error) {
      const customError = {
        name: "Validator error",
        message: error.response.data.message,
      };
      throw customError;
    }
  }
);

export const getValidatorLogsByDateRangeAndDevice = createAsyncThunk(
  "validator/getValidatorLogsByDateRangeAndDevice",
  async (payload) => {
    try {
      const response = await axios.patch(`/validators-log/filter`, payload);
      return response.data;
    } catch (error) {
      const customError = {
        name: "Validator error",
        message: error.response.data.message,
      };
      throw customError;
    }
  }
);

export const getLastLocationValidators = createAsyncThunk(
  "validatorLog/getLastLocationValidators",
  async () => {
    try {
      const response = await axios.get("/validators-log/locations");
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);


export const validatorLogSlice = createSlice({
  name: "validatorLog",
  initialState: {
    validatorLogs: { loading: false, data: [], lastLocations: [] },
  },
  reducers: {},
  extraReducers: {
    [getAllValidatorLogs.pending]: (state) => {
      state.validatorLogs.loading = true;
    },
    [getAllValidatorLogs.rejected]: (state, action) => {
      state.validatorLogs.loading = false;
    },
    [getAllValidatorLogs.fulfilled]: (state, action) => {
      state.validatorLogs.data = action.payload;
      state.validatorLogs.loading = false;
    },
    [getValidatorLogsByDateRange.pending]: (state) => {
      state.validatorLogs.loading = true;
    },
    [getValidatorLogsByDateRange.rejected]: (state) => {
      state.validatorLogs.loading = false;
    },
    [getValidatorLogsByDateRange.fulfilled]: (state, action) => {
      state.validatorLogs.data = action.payload;
      state.validatorLogs.loading = false;
    },
    [getValidatorLogsByDateRangeAndDevice.pending]: (state) => {
      state.validatorLogs.loading = true;
    },
    [getValidatorLogsByDateRangeAndDevice.rejected]: (state) => {
      state.validatorLogs.loading = false;
    },
    [getValidatorLogsByDateRangeAndDevice.fulfilled]: (state, action) => {
      state.validatorLogs.data = action.payload;
      state.validatorLogs.loading = false;
    },
    [getLastLocationValidators.pending]: (state) => {
      state.validatorLogs.loading = true;
    },
    [getLastLocationValidators.rejected]: (state) => {
      state.validatorLogs.loading = false;
    },
    [getLastLocationValidators.fulfilled]: (state, action) => {
      state.validatorLogs.lastLocations = action.payload;
      state.validatorLogs.loading = false;
  },
},
});

export default validatorLogSlice.reducer;
