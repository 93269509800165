import { Button, Dropdown, Menu, Modal, QRCode, Statistic } from "antd";
import React, { useEffect, useRef, useState } from "react";
import {
  MoreOutlined,
  EditOutlined,
  DeleteOutlined,
  StopOutlined,
  CheckOutlined,
  ExclamationCircleFilled,
  QrcodeOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const { Countdown } = Statistic;

const styles = {
  qrCodeContainer: { display: "flex", flexDirection: "row", justifyContent: "space-between", paddingTop: "26.5px" },
  refreshButton: { display: "none", backgroundColor: "green" },
  confirmButton: { color: "white", backgroundColor: "#4d609b" },
  cancelButton: { color: "#4d609b", backgroundColor: "white", borderColor: "#4d609b" },
};

const QrCodeModalContent = ({ qrCodeValue, createdTime, onRefresh }) => (
  <div style={styles.qrCodeContainer}>
    <div id="qrCode">
      <QRCode size={200} value={qrCodeValue} />
    </div>
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-around" }}>
      <Countdown title="Countdown" value={createdTime + 5 * 60 * 1000} onFinish={() => onRefresh("expired")} />
      <div id="refresh" style={styles.refreshButton}>
        <Button type="primary" style={styles.refreshButton} onClick={onRefresh}>
          Refresh
        </Button>
      </div>
    </div>
  </div>
);

const ActionButtonWithChangeStatus = ({
  record,
  onDelete,
  onRowClick,
  onChangeStatus,
  recordName,
  isValidatorPage,
  onCreateQr,
}) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [status, setStatus] = useState("active");
  const [modalContent, setModalContent] = useState(null); // Modal content state
  const { t } = useTranslation();
  const { auth } = useSelector((state) => state.auth);

  const qrCodeRef = useRef(null);
  const refreshButtonRef = useRef(null);

  useEffect(() => {
    if (qrCodeRef.current && refreshButtonRef.current) {
      if (status === "expired") {
        qrCodeRef.current.style.filter = "blur(1rem)";
        refreshButtonRef.current.style.display = "flex";
      } else if (status === "active") {
        getQrCode().then(() => {
          qrCodeRef.current.style.filter = "none";
          refreshButtonRef.current.style.display = "none";
        });
      }
    }
  }, [status]);

  const getQrCode = async (qrType) => {
    try {
      const objToSend = { type: qrType, validatorId: record.id };
      const response = await onCreateQr(objToSend);
      return response;
    } catch (error) {
      console.error("Error fetching QR Code:", error);
      Modal.error({ title: "Error", content: "Failed to fetch QR code. Please try again." });
      throw error;
    }
  };

  const getModal = async (type) => {
    const renderModal = async () => {
      const qrCodeData = await getQrCode(type); // Fetch QR code data

      const modal = Modal.info({
        title: `${type[0].toUpperCase()}${type.slice(1).toLowerCase()} QR Code`,
        content: (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              paddingTop: "26.5px",
            }}
          >
            <div id="qrCode" style={{ filter: "none" }}>
              <QRCode size={200} value={qrCodeData.qrCodeValue} />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-around",
              }}
            >
              <Countdown
                title="Countdown"
                value={new Date(qrCodeData.created).getTime() + 5 * 60 * 1000}
                onFinish={() => {
                  modal.destroy(); // Close the modal on expiry
                  openExpiredModal();
                }}
              />
            </div>
          </div>
        ),
        onOk() { },
      });
    };

    const openExpiredModal = () => {
      Modal.confirm({
        title: "QR Code Expired",
        content: "The QR Code has expired. Do you want to generate a new one?",
        okText: "Refresh",
        cancelText: "Close",
        onOk: async () => {
          await renderModal(); // Reopen the modal with a new QR code
        },
      });
    };

    await renderModal(); // Open the modal initially
  };





  const handleMenuClick = async ({ key }) => {
    switch (key) {
      case "edit":
        onRowClick(record);
        break;
      case "delete":
        Modal.confirm({
          title: (
            <>
              Are you sure you want to delete <b>{recordName || record.name}</b>{" "}
              ?
            </>
          ),
          icon: <ExclamationCircleFilled style={{ color: "#4d609b" }} />,
          okText: "Yes", // Customize OK button text
          cancelText: "No", // Customize Cancel button text
          cancelButtonProps: {
            style: {
              color: "#4d609b",
              backgroundColor: "white",
              borderColor: "#4d609b",
            },
          },
          okButtonProps: {
            style: {
              color: "white",
              backgroundColor: "#4d609b",
            },
          },
          onOk: () => {
            onDelete(record.id, () => { });
          },
          onCancel: () => { },
        });
        break;
      case "disable":
        Modal.confirm({
          title: (
            <>
              Are you sure you want to disable{" "}
              <b>{recordName || record.name}</b> ?
            </>
          ),
          icon: <ExclamationCircleFilled style={{ color: "#4d609b" }} />,
          okText: "Yes", // Customize OK button text
          cancelText: "No", // Customize Cancel button text
          cancelButtonProps: {
            style: {
              color: "#4d609b",
              backgroundColor: "white",
              borderColor: "#4d609b",
            },
          },
          okButtonProps: {
            style: {
              color: "white",
              backgroundColor: "#4d609b",
            },
          },
          onOk: () => {
            const ObjToSend = {
              ...record,
              active: false,
            };
            onChangeStatus(ObjToSend);
          },
          onCancel: () => { },
        });
        break;

      case "enable":
        Modal.confirm({
          title: (
            <>
              Are you sure you want to enable <b>{recordName || record.name}</b>
              ?
            </>
          ),
          icon: <ExclamationCircleFilled style={{ color: "#4d609b" }} />,
          okText: "Yes", // Customize OK button text
          cancelText: "No", // Customize Cancel button text
          cancelButtonProps: {
            style: {
              color: "#4d609b",
              backgroundColor: "white",
              borderColor: "#4d609b",
            },
          },
          okButtonProps: {
            style: {
              color: "white",
              backgroundColor: "#4d609b",
            },
          },
          onOk: () => {
            const ObjToSend = {
              ...record,
              active: true,
            };
            onChangeStatus(ObjToSend);
          },
          onCancel: () => { },
        });
        break;
      case "logout_qr":
        await getModal("LOGOUT");
        break;
      case "login_qr":
        await getModal("LOGIN");
        break;
      default:
        break;
    }
    setMenuVisible(false);
  };

  const renderMenuItems = () => {
    const items = [
      <Menu.Item key="edit" icon={<EditOutlined />}>
        {t("actions.edit")}
      </Menu.Item>,
    ];

    if (isValidatorPage && record.active) {
      items.push(
        <Menu.Item key="logout_qr" icon={<QrcodeOutlined />}>
          {t("qrcode.logout")}
        </Menu.Item>,
        <Menu.Item key="login_qr" icon={<QrcodeOutlined />}>
          {t("qrcode.login")}
        </Menu.Item>

      );
    }

    items.push(
      record.active ? (
        <Menu.Item key="disable" icon={<StopOutlined />} danger>
          {t("actions.disable")}
        </Menu.Item>
      ) : (
        <Menu.Item key="enable" icon={<CheckOutlined />}>
          {t("actions.enable")}
        </Menu.Item>
      )
    );

    if (auth?.loggedIn?.username !== record?.username) {
      items.push(
        <Menu.Item key="delete" icon={<DeleteOutlined />} danger>
          {t("actions.delete")}
        </Menu.Item>
      );
    }

    return items;
  };

  const menu = <Menu onClick={handleMenuClick}>{renderMenuItems()}</Menu>;

  return (
    <Dropdown
      overlay={menu}
      trigger={["click"]}
      visible={menuVisible}
      onVisibleChange={setMenuVisible}
    >
      <Button
        type="text"
        icon={<MoreOutlined />}
        onClick={(event) => event.stopPropagation()}
      />
    </Dropdown>
  );
};

export default ActionButtonWithChangeStatus;
