import React, { useEffect, useRef, memo } from "react";
import { MapContainer, TileLayer, useMap } from "react-leaflet";
import L from "leaflet";
import "leaflet.heat";
import "leaflet/dist/leaflet.css";

const HeatMap = ({ data }) => {
  const map = useMap();

  useEffect(() => {
    if (data && data.length > 0) {
      const heatLayer = L.heatLayer(data, {
        radius: 25,
        blur: 15,
        gradient: { 0.4: "blue", 0.65: "lime", 1: "red" },
      });
      heatLayer.addTo(map);

      return () => {
        map.removeLayer(heatLayer);
      };
    }
  }, [data, map]);

  return null; // This component doesn't render anything itself.
};

const HeatMapContainer = memo(({ defaultMapBounds, data }) => {
  const mapContainerRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (mapContainerRef?.current) {
        const mapInstance = mapContainerRef?.current?.leafletElement;
        mapInstance?.invalidateSize();
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <MapContainer
      ref={mapContainerRef}
      style={{ height: "450px", width: "95%", borderRadius: "8px" }}
      bounds={[
        [defaultMapBounds.southWest[0], defaultMapBounds.southWest[1]],
        [defaultMapBounds.northEast[0], defaultMapBounds.northEast[1]],
      ]}
      whenCreated={(mapInstance) => {
        setTimeout(() => mapInstance.invalidateSize(), 0);
      }}
    >
      <TileLayer
        url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
        attribution="&copy; <a href=&quot;https://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
      />
      <HeatMap data={data} />
    </MapContainer>
  );
});

export default HeatMapContainer;


















/* import { useEffect, useRef } from "react";
import L from "leaflet";
import "leaflet.heat"; // Ensure leaflet-heat is installed

const HeatMap = ({ defaultMapBounds, data = [] }) => {
  const mapRef = useRef(null);
  const mapContainerRef = useRef(null);

  useEffect(() => {
    // Initialize the map only if it hasn't been initialized already
    if (!mapRef.current && mapContainerRef.current) {
      mapRef.current = L.map(mapContainerRef.current).setView([0, 0], 2);

      if (defaultMapBounds) {
        mapRef.current.fitBounds([
          defaultMapBounds.southWest,
          defaultMapBounds.northEast,
        ]);
      }

      L.tileLayer("http://{s}.tile.osm.org/{z}/{x}/{y}.png", {
        attribution:
          '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      }).addTo(mapRef.current);

      // Add heat layer
      L.heatLayer(data, {
        blur: 35,
        gradient: { 0.4: "blue", 0.65: "lime", 1: "red" },
        maxZoom: 10,
      }).addTo(mapRef.current);
    }

    // Debounced resize function to prevent too many calls to invalidateSize
    const debouncedResize = () => {
      if (mapRef.current) {
        mapRef.current.invalidateSize();
      }
    };

    const resizeObserver = new ResizeObserver(() => {
      // Debounce invalidateSize calls to prevent layout thrashing
      clearTimeout(mapRef.current?.resizeTimeout);
      mapRef.current.resizeTimeout = setTimeout(debouncedResize, 200);
    });

    if (mapContainerRef.current) {
      resizeObserver.observe(mapContainerRef.current);
    }

    return () => {
      if (mapRef.current) {
        mapRef.current.remove();
        mapRef.current = null;
      }
      resizeObserver.disconnect();
    };
  }, [defaultMapBounds, data]);

  return (
    <div style={{ width: "100%", height: "100%", padding: "10px" }}>
      <div
        ref={mapContainerRef}
        style={{
          height: "100%",
          width: "100%",
          minHeight: "300px",
          borderRadius: "8px",
        }}
      />
    </div>
  );
};

export default HeatMap;
 */