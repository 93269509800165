import React, { useCallback , useRef, useState } from "react";
import { GoogleMap, InfoWindow, Marker, MarkerF } from '@react-google-maps/api';
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import isBetween from "dayjs/plugin/isBetween"
import timezone from 'dayjs/plugin/timezone';
import { useTranslation } from "react-i18next";
import { style } from "../../Styles";

const mapContainerStyle = {
  height: "550px",
  width: "100%"
};

const defaultCenter = {
  lat: 37.604174,
  lng: 15.688019,
};

const MapWithMultipleMarkers = ({ locations }) => {
  dayjs.extend(isBetween)
  dayjs.extend(utc)
  dayjs.extend(timezone);

  const { t } = useTranslation();

  const [activeMarker, setActiveMarker] = useState(null); // Stores the currently active marker
  const [hoveredMarker, setHoveredMarker] = useState(null);

  // Memoize the handleMarkerClick function with useCallback
  const handleMarkerClick = useCallback((location) => {
    setActiveMarker((prevMarker) =>
      prevMarker?.validatorName === location.validatorName ? null : location
    );
  }, []);



  const displayMode = (lastPing) => {
    console.log("lastPing", lastPing)
    const date = dayjs.utc(lastPing?.slice(0, 19)); // Treat the date as UTC
    const localDate = date.tz(dayjs.tz.guess())
    const now = dayjs();
    const fifteenMinutesAgo = now.subtract(15, 'minute');
    // Check if `localDbDate` is within the range
    const isWithinRange = localDate.isAfter(fifteenMinutesAgo) && localDate.isBefore(now);
    const isOffline = !lastPing || !isWithinRange;
    return (
      <span style={style.dataContainer}>
        {isOffline ? (
          <div style={style.statusInactif}>{t("validatorScreen.table.state.offline")}</div>
        ) : (
          <div style={style.statusActif}>{t("validatorScreen.table.state.online")}</div>
        )}
      </span>
    );
  }

  return (

    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      zoom={7}
      center={locations[0] || defaultCenter} // Center the map on the first location or default
    onClick={() => setActiveMarker(null)} // Close InfoWindow on map click
    >
      {locations.map((location, index) => (
        <Marker
          key={index}
          position={{ lat: location.latitude, lng: location.longitude }}
          onMouseOver={() => setHoveredMarker(location)}
          onMouseOut={() => setHoveredMarker(null)}
        >
          {/* Show InfoWindow only if this marker is currently hovered */}
          {hoveredMarker && hoveredMarker?.validatorName === location?.validatorName && (
        <InfoWindow
          key={hoveredMarker.validatorName} // Key ensures InfoWindow remounts uniquely
          position={{
            lat: hoveredMarker.latitude,
            lng: hoveredMarker.longitude
          }}
          onCloseClick={() => setActiveMarker(null)} // Close on InfoWindow's close click
        >
          <div style={{ }}>
            <h4>{hoveredMarker.validatorName}</h4>
            {displayMode(hoveredMarker.lastPing)}
          </div>
        </InfoWindow>
      )}
      </Marker>
      ))}
    </GoogleMap>

  );
}

export default MapWithMultipleMarkers;