import { Button, Drawer, Form, Select, Spin } from "antd";
import dayjs from "dayjs";
import React from "react";
import utc from "dayjs/plugin/utc";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(utc);

function TransactionFilterDrawer({
  isDrawerOpen,
  setIsDrawerOpen,
  loading,
  onSave,
  selectedKiosk,
  setSelectedKiosk,
  dateRange,
  onDateRangeChange,
  closeFilteringDrawer,
  onResetForm,
  selectedDriver, //
  setSelectedDriver, //
  selectedType, //
  setSelectedType, //
  selectedPaymentType,
  setSelectedPaymentType,
}) {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { kiosks } = useSelector((state) => state.kiosks);
  const { users } = useSelector((state) => state.users);

  const typeOptions = [
    {
      label: t("transactionScreen.transactionTypes.ticket"),
      value: "TICKET_TYPE",
    },
    {
      label: t("transactionScreen.transactionTypes.newCard"),
      value: "NEW_CARD_TYPE",
    },
    {
      label: t("transactionScreen.transactionTypes.topupCard"),
      value: "TOP_UP_CARD_TYPE",
    },
  ];

  const paymentOption =  [
    {id: "CASH",  value:"CASH" ,name:t("transactionScreen.paymentTypes.cash")},
    {id: "CARD", value:"CARD" ,name:t("transactionScreen.paymentTypes.card")},
  ];

  return (
    <Drawer
      form={form}
      title={t("transactionScreen.filterDrawer.title")}
      placement="right"
      closable={false}
      onClose={() => setIsDrawerOpen(false)}
      width={400}
      open={isDrawerOpen}
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          name={"Transactions filter"}
          layout="vertical"
          initialValues={{
            type: selectedType,
            paymentType: selectedPaymentType,
            driverId: selectedDriver,
            kiosk: selectedKiosk,
          }}
          onFinish={(values) => {
            onSave(values);
          }}
          onFinishFailed={() => {}}
          autoComplete="off"
          onValuesChange={() => {}}
        >
          <Form.Item
            label={t("transactionScreen.filterDrawer.kiosks")}
            name="kiosk"
          >
            <Select
              showarrow="true"
              showSearch
              optionFilterProp="filterBy"
              allowClear
              optionLabelProp="label"
              value={selectedKiosk}
              onChange={(value) => setSelectedKiosk(value)}
            >
              {kiosks?.kiosksList?.map((item) => (
                <Select.Option
                  key={item?.id}
                  filterBy={`${item?.name}`}
                  label={`${item?.name}`}
                  value={item?.id}
                >
                  <div>{item?.name}</div>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label={t("transactionScreen.filterDrawer.transactionTypes")}
            name={"type"}
          >
            <Select
              showSearch
              optionFilterProp="children"
              allowClear
              value={selectedType}
              onChange={(value) => setSelectedType(value)}
            >
              {typeOptions.map((option) => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label={t("transactionScreen.filterDrawer.paymentTypes")}
            name={"paymentType"}
          >
            <Select
              showSearch
              optionFilterProp="children"
              allowClear
              value={selectedPaymentType}
              onChange={(value) => setSelectedPaymentType(value)}
            >
              {paymentOption.map((option) => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label={t("transactionScreen.filterDrawer.drivers")}
            name={"driverId"}
          >
            <Select
              showSearch
              optionFilterProp="children"
              allowClear
              value={selectedDriver?.id}
              onChange={(value) => setSelectedDriver(value)}
            >
              {users?.usersList?.map((option) => {
                // Check if the option is an object or a string

                return (
                  <Select.Option key={option?.id} value={option?.id}>
                    {`${option?.name} ${option?.surname}`}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item style={{ float: "right" }}>
            <Button
              type="default"
              style={{ marginBottom: 32, marginRight: 8 }}
              onClick={() => closeFilteringDrawer()}
            >
              {t("drawerButtons.cancel")}
            </Button>
            <Button
              type="primary"
              style={{ marginBottom: 32 }}
              htmlType="submit"
            >
              {t("drawerButtons.filter")}
            </Button>
          </Form.Item>
          <Form.Item style={{ float: "left" }}>
            <Button
              type="default"
              styles={{ backgroundColor: "red" }}
              style={{ marginBottom: 32, marginRight: 8 }}
              onClick={() => onResetForm()}
            >
              {t("drawerButtons.resetFilter")}
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Drawer>
  );
}

export default TransactionFilterDrawer;
