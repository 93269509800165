import { useTranslation } from "react-i18next";
import React, { useCallback, useEffect, useState } from "react";
import { Drawer, Spin, Form, Input, Button, Divider, DatePicker, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import ShowConfirmClose from "../Modals/ShowConfirmClose";
import { changeUserPassword } from "../../redux/userSlice";

const ChangePassword = ( {onCloseDrawer}) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const initialValues = {
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
    }

    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [makingApiCall, setMakingApiCall] = useState(false);

    const dispatch = useDispatch();
    const handleSubmit = (values) => {
        if(values){

            setMakingApiCall(true);
            dispatch(changeUserPassword(values))
            .then((res) => {
                console.log(res)
                if(res.payload){
                    message.success(t("profile.passwordChangedSuccessfully"));
                    onCloseDrawer();
                    setUnsavedChanges(false);
                } else {
                    if( res?.error?.message == "PASSWORD_IS_NOT_VALID")
                    message.error(t("profile.passwordIsNotValid"));
                }
              })
              .catch((e) => {
                //message.error(e.message);
              })
              .finally(() => {
                setMakingApiCall(false);
              });
        }
    }

    const handleClose = useCallback(
        (hasUnsavedChanges) => {
          if (hasUnsavedChanges && !confirmationOpen) {
            setConfirmationOpen(true);
            ShowConfirmClose(() => {
              setConfirmationOpen(false);
              onCloseDrawer();
            });
          } else {
            onCloseDrawer();
          }
        },
        [onCloseDrawer, confirmationOpen]
      );

    const onDirty = () => {
        setUnsavedChanges(true);
    }
    return (
        
        <Drawer
        title={
            t("profile.changePassword")
        }
        placement="right"
        onClose={() => handleClose(unsavedChanges)}
        width={500}
        open
        >
            <Spin
        size="large"
        spinning={makingApiCall}
        style={{ marginTop: 64 }}
        delay={500}
      >
        <Form
        form={form}
        name="changePassword"
        layout="vertical"
        initialValues={initialValues}
        onFinish={handleSubmit}
        //onFinishFailed={onSaveFailed}
        autoComplete="off"
        onValuesChange={() => onDirty()}
        >
        <ul>
            <li>{t("profile.passwordMinLength")}</li>
            <li>{t("profile.passwordRequirementsMessage")}</li>

        </ul>
        <Form.Item
            label={t("profile.currentPassword")}
            name="oldPassword"
            rules={[
            {
                required: true,
                message: t("profile.pleaseEnterCurrentPassword"),
            },
            ]}
        >
            <Input.Password />
        </Form.Item>
        <Form.Item
            label={t("profile.newPassword")}
            name="password"
            rules={[
            {
                required: true,
                message: t("profile.pleaseEnterNewPassword"),
            },
            {
                min: 8,
                message: t("profile.passwordMinLength"),
            },
            {
                pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                message: t("profile.passwordRequirementsMessage"),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                  if (!value || getFieldValue('oldPassword') !== value) {
                      return Promise.resolve();
                  }
                  return Promise.reject(new Error(t("profile.passwordMustNotMatchCurrent")));
              },
            }),
            ]}
        >
            <Input.Password />
        </Form.Item>
        <Form.Item
            label={t("profile.confirmPassword")}
            name="confirmPassword"
            rules={[
            {
                required: true,
                message: t("profile.pleaseEnterConfirmPassword"),
            },
            ({ getFieldValue }) => ({
                validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                    }
                    return Promise.reject(new Error(t("profile.passwordsDoNotMatch")));
                },
            }),
            ]}
        >
            <Input.Password />
        </Form.Item>
        <Divider />
      <Form.Item style={{ float: "right" }}>
        <Button
          type="default"
          style={{ marginBottom: 32, marginRight: 8 }}
          onClick={() => handleClose(unsavedChanges)}
        >
          {t("drawerButtons.cancel")}
        </Button>
        <Button
          type="primary"
          style={{ marginBottom: 32 }}
          htmlType="submit"
          disabled={!unsavedChanges}
        >
          {t("drawerButtons.save")}
        </Button>
      </Form.Item>
        </Form>
        </Spin>
        </Drawer>
    
    )
}

export default ChangePassword;