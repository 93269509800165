import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../axios";

const initialState = {
  tickets: [],
};

export const getTickets = createAsyncThunk("tickets/getTickets", async () => {
  try {
    const response = await axios.get(`/tickets`);
    return response.data;
  } catch (error) {
    const customError = {
      name: error.code,
      message: error.message,
    };
    throw customError;
  }
});

export const getTicketsBetweenDate = createAsyncThunk("tickets/getTicketsBetweenDate", async (data) => {
  try {
    const response = await axios.patch(`/ticket/filter`, data);
    return response.data;
  } catch (error) {
    const customError = {
      name: error.code,
      message: error.message,
    };
    throw customError;
  }
});

export const ticketSlice = createSlice({
  name: "tickets",
  initialState,
  reducers: {},
  extraReducers: {
    [getTickets.fulfilled]: (state, action) => {
      state.tickets = action.payload;
    },
    [getTicketsBetweenDate.fulfilled]: (state, action) => {
      state.tickets = action.payload;
    },
  },
});

export default ticketSlice.reducer;
