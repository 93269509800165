import { Modal, Result, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  UserOutlined,
  EuroOutlined,
  CalendarOutlined,
  GoldOutlined,
  MinusCircleOutlined,
  QrcodeOutlined,
  CreditCardOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import { getUserById } from "../../redux/userSlice";
import { getKioskById } from "../../redux/kiosksSlice";

const { Paragraph } = Typography;

function SummaryModal({
  isModalOpen,
  handleCancel,
  selectedDriver,
  selectedDate,
  selectedKiosk,
}) {
  console.log("selectedDriver", selectedDriver);
  console.log("selectedDate", selectedDate);
  console.log("selectedKiosk", selectedKiosk);
  const [userDetails, setUserDetails] = useState(null);
  const [kioskDetails, setKioskDetails] = useState(null);
  const dispatch = useDispatch();
  const promisAllAndFetchDriverAndKioskDetails = async () => {
    const promis = [];
    if (selectedDriver) {
      promis.push(
        dispatch(
          getUserById({
            userId: selectedDriver,
          })
        )
      );
    }
    if (selectedKiosk) {
      promis.push(dispatch(getKioskById(selectedKiosk)));
    }
    await Promise.all(promis)
      .then((res) => {
        console.log("res", res);
        setUserDetails(res[0].payload);
        setKioskDetails(res[1].payload);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    if (selectedDriver || selectedKiosk) {
      promisAllAndFetchDriverAndKioskDetails();
    }
  }, [selectedDriver, selectedKiosk]);

  const { t } = useTranslation();
  const { transactions } = useSelector((state) => state.transactions);

  const calculateTotalEarnings = () => {
    let total = transactions.reduce((acc, transaction) => {
      return acc + transaction?.totalAmount;
    }, 0);
    return total?.toFixed(2);
  };

  const calculateTotalNumberOfTickets = () => {
    return transactions.reduce((acc, transaction) => {
      if(transaction?.tickets?.length > 0)
        return acc + transaction?.tickets?.length;
      else 
        return acc;
    }, 0);
  };

  const calculateTotalNumberOfCards = () => {
    return transactions.reduce((acc, transaction) => {
      if (transaction?.type === "TOP_UP_CARD_TYPE") {
        return acc + 1;
      }
      return acc; // Added return statement for other cases
    }, 0);
  };
  return (
    <Modal
      title={t("transactionSummary.title")}
      open={isModalOpen}
      onCancel={handleCancel}
      footer={null}
      width={600}
    >
      <Result status="info" title={t("transactionSummary.title")}>
        <div className="desc">
          <Paragraph>
            <QrcodeOutlined className="site-result-demo-error-icon" />{" "}
            {t("transactionSummary.totalTickets")}{" "}
            <a>{calculateTotalNumberOfTickets()} </a>
          </Paragraph>
          <Paragraph>
            <CreditCardOutlined className="site-result-demo-error-icon" />{" "}
            {t("transactionSummary.totalCards")}{" "}
            <a>{calculateTotalNumberOfCards()}</a>
          </Paragraph>
          <Paragraph>
            <EuroOutlined className="site-result-demo-error-icon" />{" "}
            {t("transactionSummary.totalEarnings")}{" "}
            <a>{calculateTotalEarnings()} EUR</a>
          </Paragraph>
          <Paragraph>
            <UserOutlined className="site-result-demo-error-icon" />{" "}
            {t("transactionSummary.selectedDriver")}{" "}
            <a>
              {selectedDriver ? (
                userDetails?.name + " " + userDetails?.surname
              ) : (
                <MinusCircleOutlined />
              )}
            </a>
          </Paragraph>
          <Paragraph>
            <CalendarOutlined className="site-result-demo-error-icon" />{" "}
            {t("transactionSummary.selectedDateRange")}{" "}
            <a>
              {selectedDate ? (
                dayjs(selectedDate[0]).format("DD/MM/YYYY") +
                " - " +
                dayjs(selectedDate[1]).format("DD/MM/YYYY")
              ) : (
                <MinusCircleOutlined />
              )}
            </a>
          </Paragraph>
          <Paragraph>
            <GoldOutlined className="site-result-demo-error-icon" />{" "}
            {t("transactionSummary.selectedKiosk")}{" "}
            <a>
              {selectedKiosk ? kioskDetails?.name : <MinusCircleOutlined />}
            </a>
          </Paragraph>
        </div>
      </Result>
    </Modal>
  );
}

export default SummaryModal;
