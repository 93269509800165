import dayjs from 'dayjs';
import utcPlugin from 'dayjs/plugin/utc';
import timezonePlugin from 'dayjs/plugin/timezone';

export function formatDateAndTime(dateString) {
	dayjs.extend(utcPlugin);
	dayjs.extend(timezonePlugin);
  
	// Assuming the dateString is in UTC but has the wrong timezone
	const date = dayjs.utc(dateString.slice(0, 19)); // Treat the date as UTC
	const localDate = date.tz(dayjs.tz.guess()); // Convert to browser's timezone
	const formattedDate = localDate.format('DD-MM-YYYY HH:mm'); // Format the date
  
	return formattedDate;
  }

export function formatDate(dateString) {
	dayjs.extend(utcPlugin);

	const date = dayjs.utc(dateString);
	const formattedDate = date.format('YYYY-MM-DD');

	return formattedDate;
}

// Utility function to check if a date is today or before
export function isTodayOrBefore(current) {
	const today = dayjs().startOf('day'); // Get the current date at the start of the day
	return current.isBefore(today); // Return true if the current date is before today
}
export function convertDateToUtc(date) {
	dayjs.extend(utcPlugin);

	const utcDate = dayjs.utc(date);
	return utcDate;
}
